import React, { useState } from "react";

import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate} from "react-router-dom";

import "./MainNavigation.css";

const MENU_ITEMS = [
  {
    name: "Home",
    link: "/",
    is_selected: true,
  },
  {
    name: "About Me",
    link: "/about",
    is_selected: false,
  },
  {
    name: "Resume",
    link: "/resume",
    is_selected: false,
  },
  {
    name: "Testimonials",
    link: "/testimonials",
    is_selected: false,
  },
  {
    name: "Contact",
    link: "/contact",
    is_selected: false,
  },
];

const MainNavigation = (props) => {
  const navigate = useNavigate();
  const [showHeaderMenu, setShowHeaderMenu] = useState(false);
  const pathname = window.location.pathname;

  const headerClickHandler = () => {
    setShowHeaderMenu(!showHeaderMenu);
    //props.onHeaderClick(true);
    navigate("/");
  };

  const logoClickHandler = () => {
    navigate("/");
  };

  const menuClickHandler = () => {
    //props.onHeaderClick(false);
    setShowHeaderMenu(false);
  };

  const getMenuOptionsClasses = (link) => {
    let classes = "menu-option menu-option-seperator ";
    if (pathname === link) {
      classes += "selected-menu-option";
    }
    return classes;
  };

  return (
    <>
    <div className="navigation-container">
      <div className="navigation-parent">
        <div className="navigation-hamburger" onClick={headerClickHandler}>
          <FontAwesomeIcon
            icon={faBars}
            className="navigation-hamburger-bars"
          />
        </div>
        <div className="logo" onClick={logoClickHandler} tabIndex="0">
        SujitKrushnaP.
        </div>
        <div
          onClick={menuClickHandler}
          className={
            showHeaderMenu
              ? "menu-options show-hamburger-options"
              : "menu-options"
          }
          >
          {MENU_ITEMS.map((menu) => (
            <div className={getMenuOptionsClasses(menu.link)} onClick={()=>  navigate(menu.link)}>
              {menu.name}
            </div>
          ))}
        </div>
      </div>
      <div className="parent-profile-picture">
          <div className="parent-profile-picture-background"></div>
      </div>
    </div>
    <div className="parent-profile-notes">
      <span>The person to whom I always look up to..</span>
    </div>
    </>
  );
};

export default MainNavigation;
