import React, { useState } from "react";
import Heading from "../../Layout/Heading";
import Bullet from "./Bullet";
import Certifications from "./Certifications";
import Educations from "./Educations";
import Interests from "./Interests";
import Summary from "./Summary";
import PersonalInfo from "./PersonalInfo";
import ProgrammingSkills from "./ProgrammingSkills";
import Projects from "./Projects";
import WorkHistory from "./WorkHistory";

import "./Resume.css";

const Resume = () => {
  
  const [index, setIndex] = useState(0);
  const [key, setKey] = useState("objective");
  const [carousalOffsetStyle, setCarousalOffsetStyle] = useState({});

  return (
    <div className="resume-container">
      <div className="resume-content">
        <Heading title={"Resume"} subHeading={"My Formal Bio Details"} />
        <div className="resume-card">
          <div className="resume-bullet">
            <Bullet
              setIndex={setIndex}
              setKey={setKey}
              setCarousalOffsetStyle={setCarousalOffsetStyle}
            />
          </div>
          <div className="resume-bullet-details">
            <div className="resume-details-carousal">
              <div className="resume-screen-container">
                {(() => {
                  switch (key) {
                    case "summary":
                      return <Summary />;
                    case "programming":
                      return <ProgrammingSkills />;
                    case "work_history":
                      return <WorkHistory />;
                    case "projects":
                      return <Projects />;
                    case "certifications":
                      return <Certifications />;
                    case "educations":
                      return <Educations />;
                    case "personal_info":
                      return <PersonalInfo />;
                    case "interests":
                      return <Interests />;
                    default:
                      return <Summary />;
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
        <div className="resume-download">
          <a href="Sujit_Paraseth_Resume.pdf" download="Sujit_Paraseth_Resume.pdf">
            <buttton className="btn highlighted-btn">Download Resume</buttton>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Resume;
