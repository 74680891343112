import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Heading from "../../Layout/Heading";

import Phone from "../../../assets/phone.png";
import Email from "../../../assets/email.png";
import Address from "../../../assets/address.png";
import loadImage from "../../../assets/loadImage.gif";
import Typical from "react-typical";
import emailjs from "emailjs-com";

import "./Contact.css";

const Contact = () => {
  const [banner, setBanner] = useState("");
  const [load, setLoad] = useState(false);
  const contactFormRef = useRef();
  const [error, setError] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoad(true);

    emailjs
      .sendForm(
        "service_bfebjzj",
        "template_bjuactj",
        contactFormRef.current,
        "user_L0kFgjUCFSVw9BAq7UR1O"
      )
      .then(
        (result) => {
          setBanner("Thank you for reaching out, will get back to you soon");
          setLoad(false);
        },
        (error) => {
          setError("Error sending message, Please try again later!");
          setLoad(false);
        }
      );
    contactFormRef.current.name.value = "";
    contactFormRef.current.subject.value = "";
    contactFormRef.current.email.value = "";
    contactFormRef.current.message.value = "";
  };

  return (
    <div className="contact-container">
      <Heading title="Contact" subHeading={"Let's Keep In Touch"} />
      <div className="contact-form">
        <div className="col">
          <h2 className="title">
            {" "}
            <Typical loop={Infinity} steps={["Get in Touch 🤝", 1000]} />
          </h2>
          <a href="https://www.facebook.com/sparaseth" target="_blank">
            <i className="fa fa-facebook-square" />
          </a>
          <a href="https://www.google.com/" target="_blank">
            <i className="fa fa-google-plus-square" />
          </a>
          <a href="https://www.instagram.com/skparaseth/" target="_blank">
            <i className="fa fa-instagram" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCpsDeEALd505LN0h2phYs4A"
            target="_blank"
          >
            <i className="fa fa-youtube-square" />
          </a>
          <a href="https://twitter.com/SParaseth22" target="_blank">
            <i className="fa fa-twitter" />
          </a>
        </div>
        <div className="contact-wrapper">
          <div className="contact-details">
            <div className="contact-item">
              <img src={Phone} alt="" className="contact-icon" />
              +91 90 220 83690
            </div>
            <div className="contact-item">
              <a href="mailto:sparaseth@gmail.com">
                <img className="contact-icon" src={Email} alt="" />
              </a>  
              sparaseth@gmail.com
            </div>
            <div className="contact-item">
              <a href = "https://www.google.com/maps/@19.069278,72.9868013,17z" target = "_blank"> 
                <img className="contact-icon" src={Address} alt="" />
              </a>
              R-301, H-018, Sector-31, Vashi, Navi Mumbai, Maharastra-400703
            </div>
          </div>
          <div className="form-background">
            <h4>Send your message</h4>
            <form ref={contactFormRef} onSubmit={handleSubmit}>
              <p>{banner}</p>
              {error && <div className="error-message">{error}</div>}
              <label htmlFor="name">Name</label>
              <input id="name" name="name" type="text" required />
              <label htmlFor="subject">Subject</label>
              <input id="subject" name="subject" type="text" required />
              <label htmlFor="email">Email</label>
              <input id="email" name="email" type="email" required />
              <label htmlFor="message">Message</label>
              <textarea id="message" name="message" type="text" required />
              <div className="contact-btn">
                <button type="submit">
                  Send <i className="fa fa-paper-plane"></i>
                  {load ? (
                    <b className="load">
                      <img src={loadImage} alt="Sending..." />
                    </b>
                  ) : (
                    ""
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
