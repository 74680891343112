import React from "react";
import { Routes, Route } from "react-router-dom";

import Layout from "./components/Layout/Layout";
import Footer from "./components/Portfolio/Footer/Footer";
import About from "./components/Portfolio/About/About";
import Home from "./components/Portfolio/Home/Home";
import Contact from "./components/Portfolio/Contact/Contact";
import Resume from "./components/Portfolio/Resume/Resume";
import Testimonials from "./components/Portfolio/Testimonials/Testimonials";
import NotFound from "./Pages/NotFound";

import "./App.css";

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/resume" element={<Resume />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
      <Footer />
    </Layout>
  );
}

export default App;
  