import React, { useState } from "react";

import "./Bullet.css";

const RESUME_BULLETS = [
  { key: "summary", label: "Summary", logoSrc: "summary.svg" },
  { key: "programming", label: "Programming Skills", logoSrc: "programming-skills.svg" },
  { key: "work_history", label: "Work History", logoSrc: "work-history.svg" },
  { key: "projects", label: "Projects", logoSrc: "projects.svg" },
  { key: "educations", label: "Educations", logoSrc: "education.svg" },
  { key: "certifications", label: "Certifications  & Trainings", logoSrc: "certificate-11.svg" },
  /*{ key: "personal_info", label: "Personal Informations", logoSrc: "personal-information.svg" },*/
  { key: "interests", label: "Interests", logoSrc: "interests.svg" },
];

const Bullet = (props) => {

  const [selectedBulletIndex, setSelectedBulletIndex] = useState(0);

  const handleCarousal = (key, index) => {
    let offSetHeight = 360;
    let newCarousalOffSet = {
      style :{
        transform: "translateY("+index * offSetHeight * -1+"px)"
      }
    }
    setSelectedBulletIndex(index);
    
    props.setKey(key);
    props.setIndex(index);
    props.setCarousalOffsetStyle(newCarousalOffSet)
    
  };
  return (
    <div className="bullet-container">
      <div className="bullet-icon"></div>
      <div className="bullets">
        {RESUME_BULLETS.map((bullet, index) => (
          <div className={
            index === selectedBulletIndex? "bullet selected-bullet": "bullet"
          } onClick={() => handleCarousal(bullet.key, index)} key={index}>
            <img
              className="bullet-logo"
              
              src={ require(`../../../assets/Resume/${bullet.logoSrc}`) }
              alt="B"
            />
            <span className="bullet-label">{bullet.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Bullet;
