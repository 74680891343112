import React from "react";
import {
  faFacebook,
  faLinkedin,
  faGithub,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Footer.css";

const SOCIALS_STATIC_DATA = [
  {
    icon: faLinkedin,
    link: "https://www.linkedin.com/in/sujit-kumar-paraseth-5b18bb12/",
  },
  {
    icon: faGithub,
    link: "https://github.com/sparaseth",
  },
  {
    icon: faTwitter,
    link: "https://twitter.com/SParaseth22",
  },
  {
    icon: faInstagram,
    link: "https://www.instagram.com/skparaseth/",
  },
  {
    icon: faFacebook,
    link: "https://www.facebook.com/sparaseth",
  }
];

const Footer = () => {
  let currentYear = new Date().getFullYear();
  return (
    <div className="main-footer-container">
      <div className="main-footer-content">
        <div className="main-footer-message">
          <span>All rights reserved &copy; skrushnap {currentYear}</span>
        </div>
        <div className="main-footer-socials">
          {SOCIALS_STATIC_DATA.map((social, index) => (
            <a rel="noopener noreferrer" href={social.link} key={index} target="_blank">
              <FontAwesomeIcon icon={social.icon} className="social-icon" />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
