import React from "react";
import { Interweave } from "interweave";
import Heading from "../../Layout/Heading";
import { useNavigate } from "react-router-dom";
import "./About.css";

const ABOUT_ME_CONSTANT = {
  description:
    "Full stack web developer with a knack of building applications with utmost efficiency. Strong professional with <strong>MCA</strong> and willing to be an asset for an organization.",
  highlights: {
    bullets: [
      "Fullstack Web Development",
      "Interactive Front End as per the design",
      "Java, Spring, Hibernate, Spring Boot Microservices",
      "React and Anular",
      "Python",
      "Building REST API using Spring Framework",
      "OSB and SOA Composite Development",
      "Managing Database",
    ],
    heading: "Here are a few highlights:",
  },
};

const About = () => {
  const navigate = useNavigate();

  const hireMeClickHandler = () => {
    navigate("/contact");
  };

  return (
    <div className="about-me-container">
      <div className="about-me-parent">
        <Heading title={"About Me"} subHeading={"Why Choose Me?"} />
        <div className="about-me-card">
          <div className="about-me-profile"></div>
          <div className="about-me-details">
            <div className="about-me-description">
              <Interweave content={ABOUT_ME_CONSTANT.description} />
            </div>
            <div className="about-me-highlights">
              <div className="highlight-heading">
                <span>{ABOUT_ME_CONSTANT.highlights.heading}</span>
              </div>
              {ABOUT_ME_CONSTANT.highlights.bullets.map((highlight, i) => (
                <div className="highlight" key={i}>
                  <div className="highlight-blob"></div>
                  <span>{highlight}</span>
                </div>
              ))}
            </div>
            <div className="about-me-options">
              <button className="btn primary-btn" onClick={hireMeClickHandler}>Hire Me</button>
              <a href="Sujit_Paraseth_Resume.pdf" download="Sujit_Paraseth_Resume.pdf">
                <button className="btn highlighted-btn">Get Resume</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
