import React from "react";

import "./ProgrammingSkills.css";

const PROGRAMMING_SKILLS = [
  { skill: "Java", ratingPercentage: 90 },
  { skill: "Spring Framework", ratingPercentage: 85 },
  { skill: "Spring Boot Microservices", ratingPercentage: 85 },
  { skill: "Build Tools - Apache Maven", ratingPercentage: 85 },
  { skill: "Hibernate/JPA", ratingPercentage: 85 },
  { skill: "Struts Framework", ratingPercentage: 85 },
  { skill: "JSF Framework", ratingPercentage: 85 },
  { skill: "Servlet/JSP", ratingPercentage: 85 },
  { skill: "React JS", ratingPercentage: 85 },
  { skill: "Angular", ratingPercentage: 85 },
  { skill: "Express JS", ratingPercentage: 50 },
  { skill: "Node JS", ratingPercentage: 60 },
  { skill: "HTML", ratingPercentage: 80 },
  { skill: "CSS", ratingPercentage: 60 },
  { skill: "Oracle ADF", ratingPercentage: 50 },
  { skill: "OSB and SOA Composite", ratingPercentage: 50 },
  { skill: "Non-Relational Databases - Mongo Db", ratingPercentage: 50 },
  {
    skill: "Relational Databases - Oracle, MySQL, PostgreSQL, MSSQL",
    ratingPercentage: 70,
  },
];

const ProgrammingSkills = () => {
  return (
    <div className="programming-skills-container">
      {PROGRAMMING_SKILLS.map((skill, index) => (
        <div className="skill-parent" key={index}>
          <div className="skill-bullet"></div>
          <span>{skill.skill}</span>
          <div className="skill-percentage">
            <div
              style={{ width: skill.ratingPercentage + "%" }}
              className="active-percentage-bar"
            ></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProgrammingSkills;
