import React from "react";
import { useNavigate } from "react-router-dom";
import Typical from "react-typical";

import "./NotFound.css";

const NotFound = () => {
  const navigate = useNavigate();

  const homeClickHandler = () => {
    navigate("/");
  };

  return (
    <div className="notfound-container">
      <div className="notfound-parent">
        <div className="notfound-content">
          <h1>404</h1>
          <h2>
            <Typical
              steps={["UH HO! You are lost.", 1000, "Page Not Found!", 2000]}
              loop={Infinity}
              wrapper="p"
            />
          </h2>
          <span>
            The page you are looking for does not exist. How you got here is a
            mystery. But you can click the button below to go back to the
            homepage.
          </span>
          <button className="btn highlighted-btn" onClick={homeClickHandler}>
            Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
