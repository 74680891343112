import React from 'react';

import './Footer.css';
import SHAPE_BG from '../../../../assets/Home/shape-bg.png';

const Footer = () => {
    return (
        <div className="footer-container">
            <div className="footer-parent">
                <img src={SHAPE_BG} alt="no internet connection" />
            </div>
        </div>
    )
}

export default Footer;
