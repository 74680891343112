import React, { Fragment } from "react";
import SubHeading from "./SubHeading";

import "./WorkHistory.css";

const WorkHistory = () => {
  return (
    <Fragment>
      <SubHeading
        heading={"CitiusTech"}
        subHeading={"Senior Backend Developer"}
        fromDate={"Apr 2021"}
        toDate={"Present"}
        description={
          "CitiusTech Healthcare Technology Private Limited is a specialized provider of healthcare technology and business process services to healthcare technology companies, healthcare providers, managed care organizations, health plans, and disease management companies."
        }
        link={"https://www.citiustech.com/"}
      />

      <SubHeading
        heading={"Larsen & Toubro Infotech"}
        subHeading={"Full Stack Developer"}
        fromDate={"Jun 2019"}
        toDate={"Apr 2021"}
        description={
          "LTI - Larsen & Toubro Infotech is a global technology consulting and digital solutions company with operations in 30 countries and focuses on accelerating the digital transformation with its Mosaic platform enabling the mobile, social, analytics, IoT and Cloud journeys."
        }
        link={"https://www.lntinfotech.com/"}
      />

      <SubHeading
        heading={"Tech Mahindra"}
        subHeading={"Technical Lead"}
        fromDate={"Jul 2017"}
        toDate={"Jun 2019"}
        description={
          "Tech Mahindra is an Indian multinational company that provides Information Technology (IT) and Business Process Outsourcing (BPO) services."
        }
        link={"https://www.techmahindra.com/"}
      />

      <SubHeading
        heading={"NEC Software Solutions"}
        subHeading={"Lead Software Engineer"}
        fromDate={"Jul 2011"}
        toDate={"Jun 2017"}
        description={
          "NEC Software Solutions (Frm. Rave Technologies) is one of the foremost providers of end-to-end IT solutions to small and mid-sized organizations across the globe. With over two decades of experience spanning the entire gamut of IT services, Rave Technologies has helped over 260 businesses in building robust and market relevant software products that surpass the highest standards of engineering and user experience."
        }
        link={"https://www.necsws.com/"}
      />

      <SubHeading
        heading={"Odisys Technologies"}
        subHeading={"Software Engineer"}
        fromDate={"May 2008"}
        toDate={"Jun 2011"}
        description={
          "Odisys Technologies has specialization in Finance, Banking, Telecom and FMCG. This company has extensive experience in different industry groups in key business areas including, customer relationship management, supply chain management, Custom Application Development, Systems Integration/EAI, ERP Functionality Extension, Business/Data Warehousing, software modernization, eBusiness, technology and outsourcing."
        }
        link={"https://odisystech.com/"}
      />
    </Fragment>
  );
};

export default WorkHistory;
