import React from "react";

import "./Certifications.css";

import CERTIFICATE_SVG from '../../../assets/Resume/certificate.svg';

const CERTIFICATIONS = [
  {
    label: "React - The Complete Guide, Udemy 2021",
  },
  {
    label: "Complete Python Developer, Udemy 2021",
  },
  {
    label: "Java Application Performance and Memory Management, Udemy 2021",
  },
  {
    label: "Docker For Java Developers, Udemy 2020",
  },

  {
    label: "Apache Maven, Udemy 2020",
  },
  {
    label: "Spring Boot Microservices with Spring Cloud, Udemy 2020",
  },
  {
    label: "Angular - The Complete Guide, Udemy 2020",
  },
  {
    label: "Certified Scrum Master (CSM), 2020",
  },
];

const Certifications = () => {
  return (
    <div className="certificate-container">
      {CERTIFICATIONS.map((cert) => (
        <div className="certificate-parent">
          <img
            className="certificate-logo"
            src={ CERTIFICATE_SVG }
            alt="B"
          />
          <span>{cert.label}</span>
        </div>
      ))}
    </div>
  );
};

export default Certifications;
