import React from "react";

import "./Summary.css";

const Summary = () => {
  return (
    <div className="summary-container">
      <div className="summary-content">
        <div className="summary-picture">
          <div className="summary-picture-background"></div>
        </div>
        <span>
          <p>
            Technically advanced Senior Java Developer with over 14+ years of strong experience in designing, developing and delivering user-centric software applications using Java/J2EE technologies and I have also worked in several projects in HR, Banking and Finance domains.
          </p>
          <p>
            I have strong knowledge of Hibernate/JPA, Spring Framework, Spring Boot, Microservices, Web Services (REST and SOAP), Maven, JavaScript, Angular, React, jQuery, MySQL, Oracle and MSSQL Server. I have worked as a Software Development Engineer with a global technology consulting and digital solutions company.
          </p>
          <p>
            I have completed Master of Computer Applications and Bachelor of Science from India.
          </p>
          <p>I am a Certified Scrum Master.</p>
        </span>
      </div>
    </div>
  );
};

export default Summary;
