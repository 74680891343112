import React from "react";
import Footer from "./Footer/Footer";
import Profile from "./Profile/Profile";

import "./Home.css";

const Home = () => {
  return (
    <div className="home-container">
      <Profile />
      <Footer />
      <div className="icon">
      <a
          href="https://www.linkedin.com/in/sujit-kumar-paraseth-5b18bb12/"
          target="_blank"
          className="linkedin"
        >
          <i class="fa fa-linkedin"></i>LinkedIn
        </a>
        <a
          href="https://github.com/sparaseth"
          target="_blank"
          className="github"
        >
          <i class="fa fa-github"></i>GitHub
        </a>
        {/*
        <a
          href="https://www.youtube.com/channel/UCpsDeEALd505LN0h2phYs4A"
          className="youtube"
          target="_blank"
        >
          {" "}
          <i className="fa fa-youtube"></i>youtube
        </a>
        */}
        <a
          href="https://twitter.com/SParaseth22"
          className="twitter"
          target="_blank"
        >
          {" "}
          <i className="fa fa-twitter"></i> twitter{" "}
        </a>
        <a
          href="https://www.instagram.com/skparaseth/"
          target="_blank"
          className="instagram"
        >
          <i class="fa fa-instagram"></i>Instagram
        </a>
        <a
          href="https://www.facebook.com/sparaseth"
          target="_blank"
          className="facebook"
        >
          <i class="fa fa-facebook-square"></i>Facebook
        </a>
      </div>
    </div>
  );
};

export default Home;
