import React from "react";

import "./SubHeading.css";

const SubHeading = (props) => {
  return (
    <div className="resume-sub-heading">
      <div className="main-heading">
        <div className="heading-bullet"></div>
        <span>{props.heading ? props.heading : ""}</span>
        {props.fromDate && props.toDate ? (
          <div className="heading-date">
            {props.fromDate + "-" + props.toDate}
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <div className="sub-heading">
        <span>{props.subHeading ? props.subHeading : ""}</span>
      </div>
      <div className="description">
        <span>
          {props.description ? props.description : ""} &nbsp;
          {props.link ? <a target="_blank" href={props.link}>{props.link}</a> : ""}
        </span>
      </div>
    </div>
  );
};

export default SubHeading;
