import React from "react";

import "./PersonalInfo.css";

const PERSONAL_INFO = [
  { key: "Name", value: "Sujit Kumar Paraseth" },
  { key: "DOB", value: "22 January 1985" },
  { key: "Gender", value: "Male" },
  { key: "Email", value: "sparaseth@gmail.com" },
  { key: "Alternate Email", value: "sparasethhotmail.com" },
  { key: "Language Known", value: "English, Hindi, Odiya" },
  { key: "Nationality", value: "Indian" },
];

const PersonalInfo = () => {
  return (
    <div className="personalinfo-container">
      {PERSONAL_INFO.map((info, index) => (
        <div className="personalinfo-parent" key={index}>
          <div className="personalinfo-bullet"></div>
          <span>{info.key}</span>
          <div className="personalinfo-detail">
            <span>&#58;{info.value}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PersonalInfo;
