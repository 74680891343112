import React from "react";

import { useNavigate } from "react-router-dom";

import "./Profile.css";

import Typical from "react-typical";

const Profile = () => {
  const navigate = useNavigate();

  const hireMeClickHandler = () => {
    navigate("/contact");
  };

  return (
    <div className="profile-container">
      <div className="profile-parent">
        <div className="profile-details">
          <div className="colz">
            <div className="colz-icon">
              <a href="https://www.linkedin.com/in/sujit-kumar-paraseth-5b18bb12/" target="_blank">
                <i class="fa fa-linkedin" aria-hidden="true"></i>
              </a>
              <a href="https://github.com/sparaseth" target="_blank">
                <i class="fa fa-github" aria-hidden="true"></i>
              </a>
              <a href="https://www.google.com/" target="_blank">
                <i class="fa fa-google-plus-square" aria-hidden="true"></i>
              </a>
              <a href="https://www.instagram.com/skparaseth/" target="_blank">
                <i className="fa fa-instagram"></i>
              </a>
              {/*
              <a
                href="https://www.youtube.com/channel/UCpsDeEALd505LN0h2phYs4A"
                target="_blank"
              >
                <i className="fa fa-youtube-square"></i>
              </a>
              */}
              <a href="https://twitter.com/SParaseth22" target="_blank">
                <i className="fa fa-twitter"></i>
              </a>
              <a href="https://www.facebook.com/sparaseth" target="_blank">
                <i className="fa fa-facebook-square"></i>
              </a>
            </div>
          </div>
          <div className="profile-details-name">
            <span className="primary-text">
              {" "}
              Hello, I am <span className="highlighted-text">Sujit Krushna Paraseth</span>
            </span>
          </div>
          <div className="profile-details-role">
            <span className="primary-text">
              {" "}
              <h3>
                {" "}
                <Typical
                  loop={Infinity}
                  steps={[
                    "Ethusiastic Dev😎",
                    1000,
                    "Fullstack Developer!💻",
                    1000,
                    "Java Developer!📱",
                    1000,
                    "Angular Developer",
                    1000,
                    "React Developer📱",
                    1000,
                    "Python Developer📱",
                    1000,
                    "OSB & SOA Developer📱",
                    1000,
                  ]}
                />
              </h3>
            </span>
            <span className="profile-role-tagline">
              Knack of building applications with front and back end operations.
            </span>
          </div>
          <div className="profile-options">
            <button className="btn primary-btn" onClick={hireMeClickHandler}>
              Hire Me{" "}
            </button>
            <a href="Sujit_Paraseth_Resume.pdf" download="Sujit_Paraseth_Resume.pdf">
              <button className="btn highlighted-btn"> Get Resume </button>
            </a>
          </div>
        </div>
        <div className="profile-picture">
          <div className="profile-picture-background"></div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
