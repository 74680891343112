import React, { Fragment } from "react";

import "./Educations.css";
import SubHeading from "./SubHeading";

const Educations = () => {
  return (
    <Fragment>
      <SubHeading
        heading={"Biju Pattanaik University Of Technology, Odisha"}
        subHeading={"Master Of Computer Application"}
        fromDate={"2005"}
        toDate={"2008"}
      />
      <SubHeading
        heading={"Berhampur University (BAMU), Berhampur, Odisha"}
        subHeading={"Bachelor Of Science"}
        fromDate={"2002"}
        toDate={"2005"}
      />
      <SubHeading
        heading={"Council Of Higher Secondary Education, Odisha"}
        subHeading={"Higher Secondary"}
        fromDate={"2000"}
        toDate={"2002"}
      />
      <SubHeading
        heading={"Board Of Secondary Education, Cuttak, Odisha"}
        subHeading={"High School"}
        fromDate={"1999"}
        toDate={"2000"}
      />
    </Fragment>
  );
};

export default Educations;
