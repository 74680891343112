import React, { Fragment } from "react";

import SubHeading from "./SubHeading";

import "./Projects.css";

const PROJECTS_DETAILS = [
  {
    title: "MultiPlan - CEP",
    duration: { fromDate: "Jul 2021", toDate: "Present" },
    description: "MultiPlan uses technology-enabled provider network, negotiation, claim pricing and payment accuracy services as building blocks for medical payors to customize the healthcare cost management programs that work best for them",
    subHeading: "Technologies Used: Java, Oracle ADF, OSB and SOA Composite, Web Services(SOAP, REST), Oracle DB, Weblogic12c",
  },
  {
    title: "Personal Portfolio",
    duration: { fromDate: "Dec 2021", toDate: "Jun 2022" },
    description:"A Personal Portfolio website to showcase all my details and projects at one place.",
    subHeading: "Technologies Used: React JS, Bootstrap",
  },
  {
    title: "Digital Wealth Platform",
    duration: { fromDate: "Aug 2020", toDate: "Apr 2021" },
    description: "Digital Wealth Platform enables customer to manage all investments and trade in listed securities in over 20 market places along with accessing Nordea Funds and Bonds through desktop, tablet and smartphone. Also, it gives customer the access to comprehensive market and corporate analyses by Nordea along with Nordea's recommendations and review.",
    subHeading: "Technologies Used: Java, Spring Framework, Spring Boot 2.x, Microservices, JUnit, Git, Maven, AngularJS, Angular, MSSQL Server",
  },
  {
    title: "DIBS - DT",
    duration: { fromDate: "Aug 2019", toDate: "Jul 2020" },
    description: "DT (DebiTech) is a payment solution provided to merchant in Nordic countries. It offers online services to shops for accepting electronic payments through a variety of payment methods such as card payment, apps and wallets payment, and invoice payment. Typically, shops use this software as a service model and form a single payment gateway for the clients (merchants) to use multiple payment methods.",
    subHeading: "Technologies Used: Java, Servlet, JSP, Hibernate/JPA, Struts 2.x, Docker, Maven, JUnit, Git, MySQL, Tomcat",
  },
  {
    title: "SCFM",
    duration: { fromDate: "Jul 2017", toDate: "Jun 2019" },
    description: "Supply Chain Finance Management (SCFM) - Product offering under supply chain finance is designed to ensure efficient management of working capital cycle, sustain growth and profitability for business partners. It’s an online platform that enables dealers and vendors to make finance requests for their purchases and receivables which result instant credit to their corresponding accounts. ",
    subHeading: "Technologies Used: Java, JSP, Servlet, Spring (Core, JDBC, Web), Web Services (SOAP), TortoiseSVN, PL/SQL, Oracle 11g, JBoss, WebSphere",
  },
  {
    title: "ResourceLink Service",
    duration: { fromDate: "Nov 2015", toDate: "May 2017" },
    description: "RL Service is an application which allows notifying the changes, and creating and getting employee information through Web Services which is being interfaced with third party application for further process.",
    subHeading: "Technologies Used: Java, Web Services (SOAP, REST), Hibernate/JPA, Spring Boot 1.x, Spring Data JPA, JUnit, Maven, Oracle 10g, MSSQL Server, JBoss AS",
  },
  {
    title: "MyView",
    duration: { fromDate: "Nov 2013", toDate: "Apr 2017" },
    description: "MyView is the self-service facility for the staff system and allows employees/managers to view and amend data according to pre-defined process and security protocols. It is an intranet application, and simplifies maintenance/management of HR systems by giving employees and managers direct access to personnel data and streamline the approval process and keep it within each department so that HR is not constantly distracted by employee requests.",
    subHeading: "Technologies Used: Java, Struts 1.x, JSF, Hibernate/JPA, Spring Framework, Spring Boot 1.x, Spring Data JPA, JUnit, Maven, AngularJS, Oracle 10g, MS SQL Server, GlassFish Application Server",
  },
  {
    title: "MyChoice",
    duration: { fromDate: "Jul 2011", toDate: "Nov 2013" },
    description: "MyChoice is an intranet HR application which gives employee an ability to select their own benefits during his/her tenure and the selected benefits will get processed with the Payroll.",
    subHeading: "Technologies Used: Java, Struts 1.x, JSF, Hibernate/JPA, Oracle 10g, Ant, JBoss AS",
  },
  {
    title: "Enrollment",
    duration: { fromDate: "Jan 2010", toDate: "Jun 2011" },
    description: "The system is used to maintain a database of customer details including biographical information, personal information and account information for the NREGS and PENSION scheme customers of Govt. of India. ",
    subHeading: "Technologies Used: Java(1.6), Servlet/JSP, JSF, JPA, MySQL, GlassFish Application Server",
  },
  {
    title: "Ideapoke",
    duration: { fromDate: "Nov 2008", toDate: "Dec 2009" },
    description: "Ideapoke is website which is designs to make social life easy and active. The main concern of this people come here and share idea and thought which is viewed by the number of people across the network. People can also create and join a wide variety of online communities to discuss current events. ",
    subHeading: "Technologies Used: Java(1.6), JSF, Hibernate, Spring, MySQL, Apache Tomcat",
  },
];

const Projects = () => {
  return (
    <Fragment>
      {PROJECTS_DETAILS.map((projectsDetail, index) => (
        <SubHeading
          key={index}
          heading={projectsDetail.title}
          subHeading={projectsDetail.subHeading}
          description={projectsDetail.description}
          fromDate={projectsDetail.duration.fromDate}
          toDate={projectsDetail.duration.toDate}
        />
      ))}
    </Fragment>
  );
};

export default Projects;
