import { Interweave } from "interweave";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Heading from "../../Layout/Heading";
import "./Testimonials.css";

const TESTIMONIALS = [
  {
    id: 1,
    name: "Nalin Giri",
    title: "Senior Solutions Architect",
    img: "nalin.jfif",
    description: "It is my pleasure to have chance to work with Sujit Paraseth in 2008-2011. <p> I was his immediate superviros at Odisys Technologies and had worked with him for nearlly three years.</p> <p>He has tremenodus potential to tackle all complex assignments by doing some r&d of his own.</p> <p>Sujit also did an excellent job in staying up to date on software deveopment and thus finding new solutions.</p> <p>He had the most extensive and diverse knowledge of software at our company and other even sought him out for advice. A reliable and highly capable employee.</p>",
    link: "https://www.linkedin.com/in/nalin-giri-6b441a4/",
  },
  {
    id: 2,
    name: "Jubin Gosar",
    title: "RTE (Release Train Engineer)",
    img: "jubin.jfif",
    description: "I have had the pleasure of working with Sujit Paraseth in 2011-2012 and then again in 2021 and both of the experiences have been wonderful. <p>He is a good team player and contributes to the good of the application that he is working on. <br/>He is a fast learner and helps his team members when they need help. <p/>He has picked up different technologies and has always shown the keenness to upskill himself.</p> <p>Even on the most recent project,  he took the initiative to learn the technical environment as well the business objectives.</p> <p>He would always be on top of things and be ready with his analysis and suggestions.</p>",
    link: "https://www.linkedin.com/in/jubingosar/",
  },
  {
    id: 3,
    name: "Shailendra Kumar",
    title: "Senior Developer",
    img: "shailendra.jfif",
    description: "It is my pleasure to have chance to work with Sujit Paraseth in 2011-2012. <p> I was his co-worker at NEC Software Solutions (Frm. Rave Technologies) and had worked with him for nearlly two years.<p/> <p>He is sincere and passionate about solving client related problems and writing clean code.<br/> He has tremenodus potential and respect his supervisors while enthusiastically tackling all assignments.<p/> <p>He always brought an energy and dependability and that will make him crucial to any teams.</p> <p> He is a friendly, easy-going person and well-liked by the co-workers and clients.</p>",
    link: "https://www.linkedin.com/in/shailendra-kumar-74ab3428/",
  },
];

const Testimonials = () => {
  return (
    <Fragment>
      <div className="testimonials-container">
        <Heading title="Testimonials" />
        <div className="testimonials-content">
          {TESTIMONIALS.map((testimonial) => (
            <div className="testimonials-card" key={testimonial.id}>
              <div className="top">
                <img
                  className="user"
                  src={ require(`../../../assets/${testimonial.img}`) }
                  alt=""
                />
              </div>
              <div className="center">
                <Interweave content={testimonial.description} />
              </div>
              <div className="bottom">
                <span>{testimonial.name}</span>
                <h4>{testimonial.title}</h4>
                <div className="fa-icon">
                  <a href={testimonial.link ? testimonial.link : "#"} target="_blank">
                    <i className="fa fa-linkedin-square"></i>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="feedback">
            Please drop me a line <Link to="/contact">here</Link>
        </div>
      </div>
    </Fragment>
  );
};

export default Testimonials;
