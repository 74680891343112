import React, { Fragment } from "react";
import MainNavigation from "./MainNavigation";

const Layout = (props) => {

  /*
  const headerClickHandler = (hideMain) => {
    const main = document.getElementById("main");
    if(hideMain){
      main.style.display = "none";
    }else{
      main.style.display = "block";
    }
  }
  */

  return (
    <Fragment>
      <MainNavigation />{/*onHeaderClick={headerClickHandler} */ }
      <main id="main">{props.children}</main>
    </Fragment>
  );
};

export default Layout;
